.btn-delete {
    background-color: #ff0000;
    color: #ffffff;
    cursor: 'pointer';
}

a.link-nav {
    color: #000000;
    text-decoration: unset;
}

a.link-nav {
    color: #000000;
    text-decoration: none;
}

.MuiFormControl-root {
    min-width: 200px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-bold {
    font-weight: 500;
}

.font-extra-bold {
    font-weight: 800 !important;
}

.btn-estimate {
    color: #0051ba;
    border: 2px solid #ddd !important;
    padding: '9px 16px !important';
    &:hover{
        background-color: #87bafc !important;
        color: #fff !important;
        border-color: #87bafc !important;
    }
}

.btn-estimate-selected {
    background-color: #cedef7 !important;
    color: #87bafc;
    border: 2px solid #87bafc !important;
    padding: '9px 16px !important';
    &:hover{
        background-color: #87bafc !important;
        color: #fff !important;
    }
}