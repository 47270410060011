body {
  overflow: auto;
  height: 100% !important;
  overflow-x: hidden;
}

#root {
  height: 100% !important;
  display: flex;
  flex-direction: column !important;
}

.root {
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  background-color: #f9fafc !important;
  font-size: 16px;
}


.ml {
  margin-left: 65px;
}

.container-menu {
  color: black;
  background-color: #f2f2f296 !important;
  z-index: 1500 !important;
}

.div-component {
  flex: 1;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-max {
  width: max-content !important;
}
.ck.ck-content:not(.ck-comment__input *) {
  height: 300px;
  width: 100% !important;
  overflow-y: auto;
}

/* .h-vh-100 {
  height: 100%;
} */

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.d-block {
  display: block;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.wp-150 {
  width: 150px !important;
}

.form-check-input:checked {
  background-color: #0051ba !important;
  border-color: #0051ba !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #0051ba !important;
}

.Mui-checked {
  color: #0051ba !important;
}

.text-second {
  color: #0051ba !important;
}
.sigCanvas {
  width: 100%;
  height: 232;
}

div[class$='jss'],
div[class$='jss']:hover {
  color: unset !important;
  background-color: unset !important;
}